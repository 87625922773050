@import url('https://cdn.jsdelivr.net/gh/openlayers/openlayers.github.io@main/dist/en/v7.0.0/legacy/ol.css');

#map {
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.mapContainer {
    height: 100%;
}