.map-site-container {
    width: 24px;
    height: 24px;
    position: relative;
    display: block;
    border-radius: 12px;
    background-color: #25791d;
}

.map-site-image {
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}