.transportation-mode-image {
    width: 25px;
    height: 25px;
    position: relative;
    top: 0px;
}

.remaining-time-image {
    width: 25px;
    height: 25px;
    position: relative;
    top: 0px;
}

.transporter-rank-container-busy, .transporter-rank-container-available {
    border-radius: 0.5em;
    border-width: 2px;
    border-style: solid;
    min-width: 1.5em;
    padding-left: 0.3em;
    padding-right: 0.3em;
}

.transporter-rank-container-busy {
    color: red;
    border-color: red;
}

.transporter-rank-container-available {
    color: green;
    border-color: green;
}

.large-passenger-vehicle-text-color {
    color: #92D050;
}
