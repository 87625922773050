html, body, #root {
    height: 100%;
    margin: 0;
}

#root {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
}

.nowrap {
    white-space: nowrap;
}

.map-site-locatable-entity {
    z-index: 1000;
}

.map-transporter-group-locatable-entity {
    z-index: 500;
}

.alert-color {
    color: red;
}

.border-radius {
    border-radius: 4px;
}

div.div-selected {
    background: linear-gradient(to right, transparent 0%, transparent 95%, #25791d 100%);
}

div.div-not-selected {
    background-color: transparent;
}