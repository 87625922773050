.map-order-group-container {
    background-color: #9B9B9B;
}

.map-order-group-container-selected {
    --selectedPercentage: 0;
    background-image: conic-gradient(#25791d calc(var(--selectedPercentage)*1%), #9B9B9B 0);
}

.map-order-group-container, .map-order-group-container-selected {
    position: relative;
    text-align: center;
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: block;
}

.map-order-group-image {
    width: 70%;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
}

.map-order-group-count-badge-label {
    position: absolute;
    top: 5px;
    right: 3px;
    font-size: xx-small;
}