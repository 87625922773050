.route-header-background {
    --topColor: white;
    --bottomColor: white;
    background-image: linear-gradient(var(--topColor), var(--bottomColor));
}

.heavy-order-image {
    width: 15px;
    height: 15px;
    margin: 0 3px 0 0 !important;
}