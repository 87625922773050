.highlighted-order {
    background-color: gold;
}

.nonhighlighted-order {
    background-color: white;
}

.assigned-order {
    background-color: #DFDFDF;
}

.order-package-image {
    width: 15px;
    height: 15px;
    margin-right: 0.2em;
    position: relative;
    top: 2px;
}

.order-pickup-image {
    width: 15px;
    height: 15px;
    margin-right: 0.2em;
    position: relative;
    top: 2px;
}

.order-dropoff-image {
    width: 15px;
    height: 15px;
    margin-right: 0.2em;
    position: relative;
    top: 2px;
}

